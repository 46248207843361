.ant-notification-notice {
  height: 60px;
  width: 412px;
  border-radius: 0 !important;
  font-family: "Noto";
}

.ant-notification-notice::before {
  background-color:#2DCCD3;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  padding-right: 5px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.ant-notification-notice-success::before {
  background-color: #47A23F;
}

.ant-notification-notice-error::before {
  background-color: #FA4616;
}

.ant-notification-notice-warning::before {
  background-color: #FFC600;
}

.ant-notification-notice-closable {
  padding: 14px 16px 14px 20px !important;
}

.ant-notification-notice-icon {
  margin-top: 4px;
}

.ant-notification .ant-notification-notice .ant-notification-notice-close {
  border-radius: 1em;
  height: 24px;
  right: 10px;
  width: 24px;
  top: 18px;
}

.ant-notification-notice-message {
  font-size: 12px !important;
  line-height: 17px !important;
  margin-bottom: 1px !important;
  margin-inline-start: 40px !important;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
  text-overflow: ellipsis;
}

.ant-notification-notice-description {
  font-size: 10px !important;
  line-height: 14px !important;
  margin-inline-start: 40px !important;
  overflow: hidden;
  white-space: nowrap;
  width: 280px;
  text-overflow: ellipsis;
}
