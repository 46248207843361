#mqtt-inspector-topic-details div[slot=center-content] {
  flex: 1 1 auto;
  min-width: 0;
}


#mqtt-inspector-topic-details .mqtt-log .react-lazylog {
  padding-left: 12px;
}

#mqtt-inspector-topic-details .mqtt-log .react-lazylog a {
  display: none !important;
}