#ace-editor {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.editor-wrapper * {
  box-sizing: border-box;
}

.editor-wrapper {
  --internal-active-color: var(--active-color, var(--primary-brand-color, var(--default-primary-brand-color)));
  --internal-gutter-color: var(--gutter-color, var(--primary-brand-color-light, var(--default-primary-brand-color-light)));
  --internal-fold-color: var(--fold-color, var(--primary-brand-color-dark, var(--default-primary-brand-color-dark)));
  --internal-border-color: var(--border-color, var(--dark-grey-text-color, var(--default-dark-grey-text-color)));
  --internal-validation-color: var(--validation-color, red);
  --internal-dirty-color: var(--dirty-color, var(--danger-color, var(--default-danger-color)));
  --internal-label-color: var(--label-color, var(--medium-grey-text-color, var(--default-medium-grey-text-color)));
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.editor-wrapper.padded {
  padding-bottom: 20px;
}

.editor-container {
  position: relative;
  border: 1px solid var(--internal-border-color);
  border-radius: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.editor-container.embedded {
  border: none;
}

.dirty-gutter {
  width: 5px;
  height: calc(100% - 20px);
  background-color: var(--internal-dirty-color);
  position: absolute;
  top: 0;
  left: -11px;
}

.dirty-gutter.embedded {
  height: 100%;
  left: 0;
  z-index: 5;
}

.code-editor-paragraph {
  margin: 6px 0;
}
.code-editor-label{
  color: var(--internal-label-color);
  margin-bottom: 6px;
}

.code-editor-error {
  color: var(--internal-validation-color);
  font-style: italic;
  overflow: hidden;
  white-space: nowrap;
  min-height: 20px;
  max-height: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.code-editor-error.embedded {
  bottom: 0;
  right: 6px;
  background-color: white;
  padding: 0 6px;
  position: absolute;
}


.ace_gutter-layer {
  background-color: var(--internal-gutter-color);
}

.ace_active-line {
  /* background-color: var(--internal-gutter-color) !important; */
  opacity: 0.3;
}

.ace_tooltip code {
  color: var(--internal-active-color);
}

.ace_gutter-active-line {
  background-color: var(--internal-active-color) !important;
}

.ace_line .ace_fold {
  background-image: none;
}
.ace_line .ace_fold:hover {
  background-image: none;
}

/* Not entire sure what this covers? Could use a better name if so */
.ace-tm .ace_fold {
  background-color: var(--internal-fold-color);
}
