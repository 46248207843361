html, body, #root {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'NotoMono';
  src: url(./assets/fonts/NotoMono-Regular.ttf);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto';
  src: url(./assets/fonts/NotoSans-Regular.ttf);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto';
  src: url(./assets/fonts/NotoSans-Italic.ttf);
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Noto';
  src: url(./assets/fonts/NotoSans-Bold.ttf);
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto';
  src: url(./assets/fonts/NotoSans-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background: var(--dark-gray-color, var(--default-dark-gray-color));
  font-size: var(--paragraphy-font-size, var(--default-paragraph-font-size));
  width: 100%;
  padding: 8px 12px;
  color: white;
  margin: 18px;
}

a:hover:visited:not(.ant-breadcrumb li span a) {
  color: var(--active-link-color);
  text-decoration: underline;
}

a:visited:not(.ant-breadcrumb li span a) {
  color: var(--visited-link-color);
  text-decoration: underline;
}

hr {
  border-top: none;
}
iron-overlay-backdrop {
  z-index: 0 !important;
}

input[type="date"] {
  font-family: Noto;
  font-size: 12px;
  border: 1px solid var(--gray-color);
  border-radius: 2px;
  height: 25px;
  padding: 0 8px;
  outline: none;
  width: 202px;
}

input[type="date"].focus-visible {
  border: 1px solid var(--primary-color);
}

unity-icon.help-icon:hover {
  color: var(--primary-color);
  cursor: pointer;
}

unity-card:not(.not-hoverable):hover {
  cursor: pointer;
}

.ant-breadcrumb li span a {
  text-decoration: none;
}

.ant-breadcrumb li:last-child span a {
  font-weight: 600;
}

.ant-collapse-header {
    align-items: center!important;
    min-width: 250px!important;
}