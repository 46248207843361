code.copyable:hover {
  cursor: pointer;
}

div.color-block {
  min-height: 20px;
  flex: 1;
  width: 100%;
  max-width: 200px;
  border: 1px solid var(--gray-color);
}

div.color-block.clickable:hover {
  cursor: pointer;
}

.dirty-input::before {
  content: "";
  padding-right: 5px;
  background: var(--primary-color);
  display: block;
  height: 27px;
  position: absolute;
  z-index: 1;
  left: -10px;
  top: 23px;
}
.dirty-input-no-label::before {
  top: 0;
}
.dirty-property-input::before {
  top: 0;
  height: 100%;
}

.dirty-input.invalid::before {
  background: var(--tertiary-2-color);
}

/* .unity-tag:hover {
  background-color: #2DCCD3 !important;
  cursor: pointer;
} */

@keyframes fadeInOut {
  0%,100% { opacity: 0 }
  10%,90% { opacity: 1 }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes appearOnTop {
  0%,100% { top: 0 }
  10%,90% { top: -20px }
}

.menu-content-action-button:not(.disabled):hover {
  background-color: var(--light-gray-2-color);
}
